/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "caption_byline_colors_default": "text-gray-900",
  "caption_byline_size_default": "text-headline-xs",
  "caption_byline_variant_default": "italic",
  "caption_text_colors_default": "",
  "caption_text_size_default": "mb-2 text-preheadline",
  "caption_text_variant_default": "",
  "caption_colors_default": "",
  "caption_size_default": "mt-3",
  "caption_variant_default": "flex flex-col",
  "group_colors_default": "",
  "group_size_default": "w-full",
  "group_variant_default": "",
  "colors_default": "bg-gray-200",
  "size_default": "w-full data-[fill]:aspect-default not-prose",
  "variant_default": "relative block object-cover"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Image");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;